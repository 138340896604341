<template>
  <b-sidebar
      id="add-new-sidebar"
      :visible="isAddNewSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Offer
        </h5>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
        >

          <b-card-code>
            <!-- Title En -->
            <validation-provider
                #default="validationContext"
                name="Title En"
                rules="required"
            >
              <b-form-group
                  label="Title En"
                  label-for="title_en"
              >
                <b-form-input
                    id="title_en"
                    v-model="rowData.title_en"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title En"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title Ar -->
            <b-form-group
                label="Title Ar"
                label-for="title_ar"
            >
              <b-form-input
                  id="title_ar"
                  v-model="rowData.title_ar"
                  autofocus
                  trim
                  placeholder="Title Ar"
              />
            </b-form-group>

            <!-- Offer Code -->
            <validation-provider
                #default="validationContext"
                name="Offer Code"
                rules="required"
            >
              <b-form-group
                  label="Offer Code"
                  label-for="offer_code"
              >
                <b-form-input
                    id="offer_code"
                    v-model="rowData.offer_code"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Offer Code"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Offer Value -->
            <validation-provider
                #default="validationContext"
                name="Offer Value"
                rules="required"
            >
              <b-form-group
                  label="Offer Value"
                  label-for="offer_value"
              >
                <cleave
                    id="number"
                    v-model="rowData.offer_value"
                    class="form-control"
                    :raw="false"
                    min="1"
                    autofocus
                    :options="mask.number"
                    placeholder="Offer Value"
                    :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Expiration Date -->
            <b-form-group
                label="Expiration Date"
                label-for="expiration_date"
            >
              <b-form-datepicker
                  v-model="rowData.expiration_date"
                  :date-disabled-fn="dateDisabled"
                  id="mc-expire-date"
                  placeholder="Expire Date"
              />
            </b-form-group>

            <!-- Offer Type -->
            <b-form-group
                label="Offer Type"
                label-for="offer_type"
            >
              <v-select
                  id="offer_type"
                  v-model="rowData.offer_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="offerTypeOptions"
              />
            </b-form-group>

            <b-form-group
                label="Courses"
                label-for="courses"
            >
              <v-select
                  v-model="selected_courses"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="title"
                  :reduce="val => val.value"
                  :options="courses"
              />
            </b-form-group>

            <!-- Image -->
            <file
                title="Select Offer Image"
                :default_place_holder_src="rowData.image"
                @file_uploaded="({media})=>{rowData.image= media.url; imageId = media.id}"
            ></file>
          </b-card-code>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
              Add
            </b-button>
            <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>

</template>

<script>
import {
  BFormCheckbox, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormDatepicker,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref} from '@vue/composition-api'
import {required, alphaNum, email} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BSidebar,
    Cleave,
    BFormDatepicker,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    vSelect,
    BCardCode,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      requireImageFlag: false,
      lists: [],
      courses: [],
      mask: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'wan',
          delimiter: '',
          numericOnly: true,
        },
      },
    }
  },
  methods:{
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      const month = date.getMonth();
      const year = date.getFullYear();

      const current = new Date();
      const currentDay = current.getDate()
      const currentMonth = current.getMonth()
      const currentYear = current.getFullYear()

      // Return `true` if the date should be disabled
      if (year < currentYear){
        return true
      }
      if (year <= currentYear){
        if (month <= currentMonth){
          if (day <= currentDay && year <= currentYear){
            return true
          }else if (day >= currentDay && month < currentMonth){
            return true
          }
        }
      }
    },
  },
  created() {
    this.$http.get('/all-courses').then(response => {
      let that = this
      this.lists = response.data.data.map(function(item){
        that.courses.push({
          title : item.title_en,
          value: item.id+""
        })
      })
    })
  },
  setup(props, {emit}) {
    const blankData = {
      title_en: '',
      title_ar: null,
      is_active: true,
      expiration_date: '',
      image: null,
      offer_value: '',
      offer_code: '',
      offer_type: '',
    }
    const imageId = ref(null);
    const selected_courses = ref(null);
    const rowData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      rowData.value = JSON.parse(JSON.stringify(blankData))
    }

    const offerTypeOptions = ref([
      {
        title: 'Percentage',
        value: 1
      },
      {
        title: 'Value',
        value: 2
      },
    ])
    const onSubmit = () => {
      let data = {
        title_en: rowData.value.title_en,
        title_ar: rowData.value.title_ar,
        is_active: rowData.value.is_active,
        expiration_date: rowData.value.expiration_date,
        offer_value: rowData.value.offer_value,
        offer_type: rowData.value.offer_type.value,
        offer_code: rowData.value.offer_code,
        image: imageId.value,
        selected_courses: selected_courses.value
      }
      store.dispatch('app-offer/add', data)
          .then(() => {
            emit('reFetch-data')
            emit('update:is-add-new-sidebar-active', false)
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      rowData,
      onSubmit,
      imageId,
      selected_courses,
      refFormObserver,
      getValidationState,
      resetForm,
      offerTypeOptions
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
