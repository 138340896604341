<template>

  <div>

    <offer-list-add-new
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        @reFetch-data="reFetchData"
    />

    <!-- Filters -->
    <offers-list-filters
        :status-filter.sync="statusFilter"
        :status-options="statusOptions"
        :offer-type-filter.sync="offerTypeFilter"
        :offer-type-options="offerTypeOptions"
    />

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
              />
              <b-button
                  variant="primary"
                  @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">Add Offer</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Status -->
        <template #cell(is_active)="data">
          <status
              :row=data.item
              :module_be_base_url=module_base_url
          >
          </status>
        </template>

        <!-- Column: flag -->
        <template #cell(image)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.image"
                  :text="''"
                  :variant="''"
              />
            </template>
          </b-media>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
                :id="`edit-row-${data.item.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({name : 'edit-offer', params: {id: data.item.id}})"
            />
            <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`edit-row-${data.item.id}-send-icon`"
            />

            <feather-icon
                :id="`trash-row-${data.item.id}-send-icon`"
                icon="TrashIcon"
                style="margin-left: 1rem!important;"
                @click="deleteItem(data.item.id)"
                class="cursor-pointer"
                size="16"
            />
            <b-tooltip
                title="Delete"
                class="cursor-pointer"
                :target="`trash-row-${data.item.id}-send-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{
                dataMeta.of
              }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalData"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText} from '@core/utils/filter'
import OffersListFilters from './OffersListFilters.vue'
import offerStoreModule from '../offerStoreModule'
import OfferListAddNew from './OfferListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from 'vue-toastification/composition'
import useOffersList from './useOffersList'

export default {
  data() {
    return {
      module_base_url: "offers",
      module_be_base_url: "offers",
    }
  },
  components: {
    OfferListAddNew,
    OffersListFilters,

    BCard,
    BTooltip,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods:{
    deleteItem(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.onDelete(id)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }
  },
  setup() {
    const OFFER_APP_STORE_MODULE_NAME = 'app-offer'
    const toast = useToast()

    // Register module
    if (!store.hasModule(OFFER_APP_STORE_MODULE_NAME)) store.registerModule(OFFER_APP_STORE_MODULE_NAME, offerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OFFER_APP_STORE_MODULE_NAME)) store.unregisterModule(OFFER_APP_STORE_MODULE_NAME)
    })

    const isAddNewSidebarActive = ref(false)

    const onDelete = (id) => {
      store.dispatch('app-offer/delete', id)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Offer Deleted Successfully',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            reFetchData()
          })
    }
    const statusOptions = [
      {label: 'Active', value: '1'},
      {label: 'Inactive', value: '0'},
    ]
    const offerTypeOptions = [
      {label: 'Percentage', value: '1'},
      {label: 'Value', value: '2'},
    ]

    const {
      fetchData,
      reFetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      offerTypeFilter,
    } = useOffersList()

    return {
      fetchData,
      reFetchData,
      onDelete,
      // Sidebar
      isAddNewSidebarActive,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      // Filter
      avatarText,
      statusOptions,
      offerTypeOptions,
      statusFilter,
      offerTypeFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
